<template>
    <div class="shop_main noMenu" style="background: #FFF">
        <div class="shop_order_main">

            <userMenu type="31" />

            <div class="shop_order_list">
                <div class="shop_address_title">
                    <h1 class="title">账户安全 / <span>修改手机号码</span></h1>
                </div>

                <div class="shop_form mt30">
                    <el-form ref="form" :model="form" :rules="rules" label-width="84px" size="small">
                        <el-form-item label="手机号：" class="mb32" prop="phone">
                            <el-input v-model="form.phone" placeholder="请填写11位数的手机号码" class="fullInput"></el-input>
                        </el-form-item>
                        <el-form-item label="验证码：" class="mb32" prop="code">
                            <el-input v-model="form.code" placeholder="请输入验证码" class="phoneInput ml0"></el-input>
                            <el-button :class="isSendCode ? 'on' : ''" type="primary" plan class="halfInput" :loading="isRequestSend" @click="toSendCode">{{isSendCode ? smsBtnText : '获取验证码'}}</el-button>
                            <div class="tip">输入新手机号及验证码后完成校验则提交修改</div>
                        </el-form-item>

                        <el-form-item>
                            <el-button type="primary" :loading="submitLoading" @click="submitForm('form')">提交修改</el-button>
                        </el-form-item>
                    </el-form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
  import {postUserPhone, smsSend, smsVerify} from "../../../api/public";
  import {regular_phone} from "../../../utils/regular";

  const userMenu = () => import('../../../components/layout/shop/userMenu')
  export default {
    name: "shopPhone",
    data () {
      return {
        form: {
          phone: '',
          code: ''
        },
        rules: {
          phone: [
            { required: true, message: '请填写11位数的手机号码', trigger: 'blur' },
            { pattern: '^1[3456789]\\d{9}$', message: '请输入正确的手机号码', trigger: 'blur'}
          ],
          code: [
            { required: true, message: '请输入验证码', trigger: 'blur' }
          ],
        },
        isRequestSend: false,
        submitLoading: false,
        isSendCode: false,
        smsBtnText: ''
      }
    },
    created() {
      this.$store.commit('changeScrollTop');
    },
    methods: {
      toSendCode () {
        if (this.isSendCode) {
          return false
        }
        if (this.form.phone === '') {
          this.$message({
            message: '请输入您的手机号',
            showClose: true,
            type: 'warning'
          })
          return false
        }
        if (!regular_phone.test(this.form.phone)) {
          this.$message({
            message: '请输入正确的手机号',
            showClose: true,
            type: 'warning'
          })
          return false
        }
        this.isRequestSend = true
        smsSend({
          data: {
            phone: this.form.phone,
            type: 2
          }
        }).then(res => {
          this.isRequestSend = false
          if(res.code === 0) {
            this.isSendCode = true
            this.toChangeSmsTime(60)
          }
        }).catch(() => {
          this.isRequestSend = false
        })
      },
      toChangeSmsTime (time) {
        if (time === 0) {
          this.isSendCode = false
        } else {
          this.isSendCode = true
          this.smsBtnText = '重新获取(' + time + 's)'
          let times = time - 1
          let that = this
          setTimeout(() => {
            that.toChangeSmsTime(times)
          }, 1000)
        }
      },
      submitForm (formName) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            this.submitLoading = true
            smsVerify({
              data: {
                phone: this.form.phone,
                type: 2,
                code: this.form.code
              }
            }).then(res => {
              if (res.code === 0) {
                this.toChangePhone()
              } else {
                this.submitLoading = false
              }
            }).catch(() => {
              this.submitLoading = false
            })
          }
        })
      },
      toChangePhone () {
        postUserPhone({
          data: {
            phone: this.form.phone
          }
        }).then(res => {
          this.submitLoading = false
          if (res.code === 0) {
            this.type = 1
            this.$message({
              message: '手机号修改成功',
              showClose: true,
              type: 'success'
            })
            this.$refs['form'].resetFields()
            localStorage.removeItem('access_token_shop')
            this.$router.replace('/login')
          }
        }).catch(() => {
          this.submitLoading = false
        })
      }
    },
    components: {
      userMenu
    }
  }
</script>
